import Icon from "@atoms/icon/Icon";
import { cn } from "@utils/tailwind";
import { ArrowIconProps } from "./ArrowIcon.types";

export const ArrowIcon = ({ color = "light", className }: ArrowIconProps) => (
  <div
    className={cn(
      "relative block size-10 overflow-hidden rounded-full border transition-colors",
      color === "light"
        ? "border-content-primary-on-light  group-hover/link:bg-content-primary-on-light group-focus/link:bg-content-primary-on-light"
        : "border-content-primary-on-dark  group-hover/link:border-green-accent-two group-hover/link:bg-green-accent-two group-focus/link:bg-green-accent-two",
      className
    )}
  >
    <Icon
      name="ArrowTopRight"
      className={cn(
        "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-transform duration-300",
        "group-hover/link:translate-x-[50px] group-hover/link:translate-y-[-50px]",
        "group-focus/link:translate-x-[50px] group-focus/link:translate-y-[-50px]"
      )}
    />
    <Icon
      name="ArrowTopRight"
      className={cn(
        "absolute left-1/2 top-1/2 translate-x-[-50px] translate-y-[50px] text-content-primary-on-dark transition-transform duration-300",
        "group-hover/link:-translate-x-1/2 group-hover/link:-translate-y-1/2",
        "group-focus/link:-translate-x-1/2 group-focus/link:-translate-y-1/2"
      )}
    />
  </div>
);
