import { cn } from "@utils/tailwind";
import { FC } from "react";
import { OverlayProps } from "../SuccessStoriesSection.types";
import { StatementsCard } from "../cards/StatementsCard";

export const MobileOverlay: FC<Omit<OverlayProps, "className">> = ({
  show,
  ...props
}) => (
  <StatementsCard
    {...props}
    className={cn("absolute top-full md:hidden", show && "top-0")}
  />
);
