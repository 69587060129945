"use client";

import Typography from "@atoms/typography/Typography";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { cn } from "@utils/tailwind";
import { useResolution } from "@utils/use-resolution/useResolution";
import { FC, useEffect, useRef, useState } from "react";
import { BurgerLayer } from "./BurgerLayer/BurgerLayer";
import { BurgerSectionTypes } from "./BurgerSection.constants";
import {
  BurgerSectionAccordeon,
  desktopScroll,
  prepareStylesForDesktop,
  useSelectActiveLayer,
} from "./BurgerSection.helpers";

export const BurgerSection: FC<BurgerSectionTypes> = ({
  title,
  layers,
  themeName,
  anchorId,
}) => {
  const resolution = useResolution();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [wrapperHeight, setWrapperHeight] = useState<number | undefined>(
    undefined
  );
  const [windowWidth, setWindowWidth] = useState<number | undefined>(undefined);

  const { activeLayer, setActiveLayer } = useSelectActiveLayer(
    windowWidth,
    wrapperRef
  );

  useEffect(() => {
    if (wrapperRef.current) {
      const defaultWrapperHeight = wrapperRef.current.offsetHeight;
      setWrapperHeight(defaultWrapperHeight + 900);
    }
  }, [wrapperRef]);

  useEffect(() => {
    if (resolution.width) {
      const windowWidth = resolution.width - 10;
      setWindowWidth(windowWidth);
    }
  }, [resolution]);

  return (
    layers &&
    layers.length === 3 && (
      <ContainerSpacing
        id={anchorId}
        ref={wrapperRef}
        data-theme={themeName}
        withBackground
        className="!p-0"
        style={
          windowWidth && windowWidth >= 1270 && wrapperHeight
            ? { height: `${wrapperHeight}px` }
            : {}
        }
      >
        <div className="container-flexible py-[90px] sm:py-[100px] md:py-[120px] xl:sticky xl:top-0 xl:py-[160px] 2xl:py-[200px]">
          <Typography
            tag="h2"
            variant="h2"
            className="mb-14 text-primary md:mb-20"
          >
            {title}
          </Typography>
          <div className="relative mb-14 md:flex md:flex-wrap md:align-top 2xl:mx-auto 2xl:max-w-[1100px]">
            {layers.map((layer, index) => (
              <div
                key={`${layer.title}-${index}`}
                onClick={() => {
                  setActiveLayer((prevValue) =>
                    prevValue === index ? undefined : index
                  );
                  if (windowWidth && windowWidth >= 1270) {
                    desktopScroll(index, wrapperRef);
                  }
                }}
                className={cn(
                  "flex justify-center transition-transform duration-500 md:relative md:block md:w-full md:min-w-full md:justify-end",
                  index === 0 && "z-30",
                  index === 1 && "z-20",
                  index === 2 && "z-10",
                  activeLayer === index && "z-40",
                  prepareStylesForDesktop(index, activeLayer)
                )}
              >
                <BurgerLayer
                  image={layer.image}
                  activeLayer={activeLayer}
                  layerIndex={index}
                />
                <BurgerSectionAccordeon
                  index={index}
                  activeLayer={activeLayer}
                  layer={layer}
                  className="hidden md:block"
                  setActiveLayer={setActiveLayer}
                  themeName={themeName}
                  windowWidth={windowWidth}
                  wrapperRef={wrapperRef}
                />
              </div>
            ))}
          </div>
          <div className="md:hidden">
            {layers.map((layer, index) => (
              <BurgerSectionAccordeon
                key={`${layer.description}-accordeon-${index}`}
                index={index}
                activeLayer={activeLayer}
                layer={layer}
                className="md:hidden"
                setActiveLayer={setActiveLayer}
                themeName={themeName}
                windowWidth={windowWidth}
                wrapperRef={wrapperRef}
              />
            ))}
          </div>
        </div>
      </ContainerSpacing>
    )
  );
};
