import { Looper } from "@atoms/looper/Looper";
import { LogoLooperProps } from "@molecules/logo-looper/LogoLooper.types";
import { Logos } from "@molecules/logo-looper/Logos";
import { cn } from "@utils/tailwind";
import { FC } from "react";

export const LogoLooper: FC<LogoLooperProps> = ({
  logos,
  className,
  speed = 25,
  direction = "left",
}) => {
  if (logos.length === 0) return null;

  const startLoopingEarly = logos.length > 9;

  return (
    <>
      {/* Mobile */}
      <Looper
        className={cn(
          "relative left-2/4 w-[calc(100vw-10px)] -translate-x-2/4",
          !startLoopingEarly && `2xl:hidden`,
          className
        )}
        speed={speed}
        direction={direction}
      >
        <Logos logos={logos} isMobile />
      </Looper>

      {/* Desktop */}
      <ul
        className={cn(
          "hidden list-none flex-wrap items-center gap-4 pl-0",
          !startLoopingEarly && `2xl:flex`,
          logos.length > 5 && "justify-between",
          className
        )}
      >
        <Logos logos={logos} />
      </ul>
    </>
  );
};
