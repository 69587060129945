import { Button } from "@atoms/button/Button";
import { ButtonProps, IconProps } from "@atoms/button/Button.types";
import { cn } from "@utils/tailwind";
import { FC } from "react";

export const PillButton: FC<ButtonProps & IconProps> = (props) => (
  <Button
    {...props}
    icon="ArrowRightShort"
    size="small"
    className={cn("size-max px-4 py-2 sm:px-8 sm:py-3", props.className)}
    iconClassName={cn("w-auto", props.iconClassName)}
  />
);
