import Typography from "@atoms/typography/Typography";
import { ArrowIcon } from "@molecules/arrow-icon/ArrowIcon";
import { cn } from "@utils/tailwind";
import Link from "next/link";
import { FC } from "react";
import { CardProps } from "../BigNumbers.types";

export const Card: FC<CardProps> = ({ number, description, href }) => {
  const Comp = href ? Link : "div";

  const numberSize = cn(`text-7xl md:text-8xl xl:text-[140px]`);
  const afterNumber = cn(
    `after-text-outline-none relative font-semibold`,
    `after:absolute after:left-0 after:top-0 after:block after:text-content-primary-on-light after:content-[attr(data-content)]`,
    `after:text-7xl md:after:text-8xl xl:after:text-[140px]`,
    `pr-0 md:pr-10 lg:pr-0`,
    `after:transition-colors group-hover/link:after:text-green-accent-one`
  );

  return (
    <>
      {(number || description) && (
        <Comp
          className={cn(
            "group/link relative",
            "block translate-x-px translate-y-px md:flex lg:block",
            "px-6 py-8 md:p-9 lg:p-10",
            "border-b border-grey-scale-text lg:border-r",
            "focus-within:bg-black/5 focus:outline-none"
          )}
          target={href ? "_self" : undefined}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          href={href ? href : (undefined as any)}
        >
          {number && (
            <div
              className={cn(
                "text-outline-green",
                numberSize,
                afterNumber,
                "w-auto md:w-64 lg:w-auto"
              )}
              data-content={number}
            >
              {number}
            </div>
          )}
          {description && (
            <Typography
              tag="div"
              variant="base"
              className="mt-5 w-auto pr-0 md:mt-0 md:w-[calc(100%-290px)] md:pr-10 lg:mt-5 lg:w-auto lg:pr-0"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
          {href && (
            <div className="absolute right-6 top-6 block md:relative md:right-auto md:top-auto md:w-fit lg:hidden">
              <ArrowIcon color="dark" />
            </div>
          )}
        </Comp>
      )}
    </>
  );
};
