import Typography from "@atoms/typography/Typography";
import ArrowIcon from "@icons/arrow-icon";
import { cn, tw } from "@utils/tailwind";
import { FC } from "react";
import { StatementsCardProps } from "../SuccessStoriesSection.types";

const baseRootStyles = tw`z-10
  flex
  size-full
  flex-col
  justify-between
  gap-6
  bg-content-primary-on-dark
  p-6
  pt-8
  text-content-primary-on-light
  transition-[top,right,bottom,left]
  duration-500
  ease-in-out
  xl:px-8
  xl:pb-8
  xl:pt-0
  2xl:pb-8
  2xl:pl-10
  2xl:pr-8`;

const arrowWrapperBaseStyles = tw`group
  size-11
  self-end
  border-current
  outline-none
  transition-colors
  hover:border-content-primary-on-light
  hover:bg-content-primary-on-light
  focus:border-green-accent-one`;

const arrowIconStyles = tw`ml-0.5
  mt-px
  transition-colors
  group-hover:fill-content-primary-on-dark
  group-focus:fill-green-accent-one`;

const circleStyles = tw`flex
  size-8
  flex-none
  items-center
  justify-center
  rounded-full
  border
  border-green-accent-one`;

export const StatementsCard: FC<StatementsCardProps> = ({
  statements,
  url,
  className,
}) => {
  const rootStyles = cn(baseRootStyles, className);
  const arrowWrapperStyles = cn(
    circleStyles,
    arrowWrapperBaseStyles,
    !url && "hidden"
  );

  return (
    <div className={rootStyles}>
      <ol className="flex flex-col gap-4 pr-4 empty:hidden md:pr-0 lg:gap-6 2xl:gap-4">
        {statements.map((text, ind) => (
          <li key={`${text}-${ind}`} className="flex gap-4">
            <p className={cn(circleStyles, "text-sm font-medium")}>{ind + 1}</p>
            <Typography
              tag="p"
              wysiwygStyling
              dangerouslySetInnerHTML={{ __html: text }}
              variant="base"
              className="md:text-sm xl:text-base"
            />
          </li>
        ))}
      </ol>
      {url && (
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          className={arrowWrapperStyles}
        >
          <ArrowIcon width={11} height={11} className={arrowIconStyles} />
        </a>
      )}
    </div>
  );
};
