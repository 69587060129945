import { Image } from "@atoms/image/Image";
import Typography from "@atoms/typography/Typography";
import { cn, tw } from "@utils/tailwind";
import { FC } from "react";
import { EventProps } from "../EventsListSection.types";
import { EventInfo } from "./EventInfo";
import { Speaker } from "./Speaker";

export const Event: FC<EventProps> = (props) => {
  const rootStyles = cn(
    `grid border-b border-solid border-card pb-[2.375rem] pt-2.5 first:border-t
    md:grid-cols-[min-content_1fr]
    md:gap-x-8 md:gap-y-5
    md:pb-[1.875rem]
    lg:gap-x-9 lg:gap-y-4
    xl:grid-cols-[min-content_0.68fr_1.32fr]
    2xl:grid-cols-[min-content_0.76fr_1.24fr]
    2xl:gap-10`,
    props.className
  );

  const speakersStyles = tw`grid gap-7
    md:grid-cols-2 md:gap-x-20 md:gap-y-5
    lg:grid-cols-3 lg:gap-x-10
    xl:grid-cols-2 xl:gap-x-14
    2xl:grid-cols-3 2xl:gap-x-10 2xl:gap-y-6`;

  const imageStyles = cn(
    "object-cover transition-all duration-300 ease-in-out",
    props.href && "hover:scale-105 hover:opacity-90"
  );

  const linkStyles = tw`relative my-2 h-[4.375rem] w-[7.5rem]
    overflow-hidden rounded
    md:h-[7.5rem] md:w-52
    lg:h-[8.438rem]
    xl:my-2.5 xl:h-36 xl:w-56
    2xl:h-40 2xl:w-[15.5rem]`;

  return (
    <div className={rootStyles}>
      {props.image && (
        <a
          href={props.href}
          target="_blank"
          rel="noopener noreferrer"
          title={props.description}
          className={linkStyles}
        >
          <Image
            backgroundImage
            maxKnownWidth={248}
            imageData={props.image}
            className={imageStyles}
          />
        </a>
      )}
      <EventInfo {...props} />
      {props.speakers.length > 0 && (
        <div className="md:col-span-2 xl:col-span-1 xl:my-5">
          <Typography
            tag="p"
            variant="sm"
            weight="medium"
            className="mb-4 uppercase tracking-[0.04em] text-grey-scale-off-black md:mb-5"
          >
            contact us for more details
          </Typography>
          <div className={speakersStyles}>
            {props.speakers.map((speaker) => (
              <Speaker key={speaker.id} {...speaker} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
