import { screens } from "@tailwind/screens";
import { RefObject, useEffect, useState } from "react";
import { HorizontalStep } from "./HorizontalStepsSection.types";

export const COLUMN_SIZE = 300;

export const useSelectActiveStep = (
  steps: HorizontalStep[],
  windowWidth: number | undefined,
  wrapperRef: RefObject<HTMLDivElement>
) => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (wrapperRef.current) {
        const sectionTop = wrapperRef?.current?.getBoundingClientRect().top;

        const ind = steps
          .map((_, ind) => ind * -1 * COLUMN_SIZE)
          .findLastIndex((it) => sectionTop <= it);

        setActiveStep(Math.max(0, ind));
      }
    };
    if (windowWidth && windowWidth >= screens.xl) {
      window.addEventListener("scroll", handleScroll, { passive: false });
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setActiveStep, steps, windowWidth, wrapperRef]);

  return {
    activeStep,
    setActiveStep,
  };
};

export const mobileScroll = (event: React.MouseEvent<HTMLDivElement>) => {
  const target = event.currentTarget;
  target.scrollIntoView({
    behavior: "smooth",
    block: "nearest",
    inline: "start",
  });
};

export const desktopScroll = (
  index: number,
  wrapperRef: RefObject<HTMLDivElement>
) => {
  const sectionTop = wrapperRef?.current?.getBoundingClientRect().top;
  if (sectionTop || sectionTop === 0) {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const pos = sectionTop + scrollTop;
    window.scrollTo({
      top: index === 0 ? pos : pos + index * COLUMN_SIZE,
      behavior: "smooth",
    });
  }
};
