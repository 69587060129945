import { PillButton } from "@molecules/pill-button/PillButton";
import { ControlsProps } from "@sections/timeline/components/types";
import { cn } from "@utils/tailwind";
import { FC, useMemo } from "react";
import { useSwiper } from "swiper/react";
import { getSlidesInfo } from "./Controls.helpers";
import styles from "./Controls.module.css";
import { NavigationLine } from "./NavigationLine";

export const Controls: FC<ControlsProps> = ({
  curSlideInd,
  slides: allSlides,
}) => {
  const swiper = useSwiper();

  const slidesInfo = useMemo(
    () => getSlidesInfo(curSlideInd, allSlides),
    [curSlideInd, allSlides]
  );

  const { slides, years } = slidesInfo;

  const prevYearStyles = cn(
    "t-headline-h3 -translate-x-5 text-blue-light xl:-translate-x-6 2xl:-translate-x-9",
    slides.isFirstSlide && "opacity-40",
    styles.strokeTextBlueLight
  );

  const nextYearStyles = cn(
    "t-headline-h3 translate-x-5 text-green-saturated xl:translate-x-6 2xl:translate-x-9",
    slides.isLastSlide && "opacity-40",
    styles.strokeTextGreenSaturated
  );

  return (
    <div>
      <div
        data-name="Controls"
        className="container-flexible mb-5 flex items-center justify-between md:mb-8 xl:mb-10 2xl:mb-12"
      >
        <div className={prevYearStyles}>{years.prev}</div>

        <div className="flex items-center gap-5 md:gap-6 xl:gap-7 2xl:gap-12">
          <PillButton
            background="dark"
            disabled={slides.isFirstSlide}
            onClick={() => swiper.slidePrev()}
            iconClassName="rotate-180"
          />

          <div className="t-headline-h3 text-blue-light">{years.cur}</div>

          <PillButton
            background="dark"
            onClick={() => swiper.slideNext()}
            disabled={slides.isLastSlide}
          />
        </div>

        <div className={nextYearStyles}>{years.next}</div>
      </div>

      <NavigationLine curSlideInd={curSlideInd} slidesInfo={slidesInfo} />
    </div>
  );
};
