import { Image } from "@atoms/image/Image";
import { cn, tw } from "@utils/tailwind";
import { FC } from "react";
import { OpenSourceCard } from "../TimelineSection.types";

export const TimelineCard: FC<OpenSourceCard> = (data) => {
  const forks = data.stats.forks === 1 ? "1 fork" : `${data.stats.forks} forks`;
  const stars = data.stats.stars === 1 ? "1 star" : `${data.stats.stars} stars`;

  const rootStyles = tw`group grid items-center overflow-hidden rounded-3xl
    border border-solid border-card no-underline
    xl:grid-cols-[0.33fr_1.67fr]
    2xl:grid-cols-[0.42fr_1.58fr]`;

  const leftColStyles = cn(
    `hidden group-hover:opacity-90
    xl:grid xl:h-full
    xl:place-items-center xl:px-2.5 xl:py-0
    2xl:px-2.5 2xl:py-0`,
    data.icon.bgColor === "blue" ? "bg-blue-saturated" : "bg-organic-darker"
  );

  const rightColStyles = tw`relative bg-card p-5 group-hover:opacity-90 xl:p-5 2xl:p-7`;

  const smallIconContainerStyles = cn(
    "absolute right-0 top-0 aspect-square h-auto w-12 overflow-hidden rounded-3xl xl:hidden",
    data.icon.bgColor === "blue" ? "bg-blue-saturated" : "bg-organic-darker"
  );

  const bodyTextStyles = tw`font-sans text-sm font-medium leading-5 text-secondary`;

  return (
    <a
      href={data.url ?? ""}
      target="_blank"
      rel="noreferrer"
      className={rootStyles}
    >
      <div data-name="Left col" className={leftColStyles}>
        <div data-name="LargeIconContainer" className="relative h-3/6 w-full">
          <Image
            backgroundImage
            maxKnownWidth={100}
            alt="Icon"
            imageData={data.icon.src}
            className="object-contain"
          />
        </div>
      </div>

      <div data-name="Right col" className={rightColStyles}>
        <div className="t-headline-h5 mb-4 text-card-title xl:mb-2.5 2xl:mb-4">
          {data.title}
        </div>

        <div className={smallIconContainerStyles}>
          <Image
            backgroundImage
            alt="Icon"
            imageData={data.icon.src}
            className="object-contain p-0.5"
          />
        </div>

        <div className={cn(bodyTextStyles, "mb-4 xl:mb-2.5 2xl:mb-4")}>
          {data.bodyText}
        </div>

        <div className={cn(bodyTextStyles, "flex gap-2.5")}>
          <span>{forks}</span>
          <span>{stars}</span>
        </div>
      </div>
    </a>
  );
};
