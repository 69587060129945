/**
 * It calculates a necessary amount of animated items that need to be rendered
 * inside the container with the given "availableWidth" to have a Loop effect.
 *
 * This function finds minimal x where:
 * (x - 1) * itemWidth >= availableWidth
 */
export const calcAnimatedItemsAmount = (
  itemWidth?: number,
  availableWidth?: number
) => {
  // We pretend that item width is a bit lower than the actual width
  // because we make a division, and it's not a 100% precise operation
  const slightlyDecreasedItemWidth = (itemWidth || 0) - 5;
  return slightlyDecreasedItemWidth > 0 && availableWidth
    ? Math.ceil(availableWidth / slightlyDecreasedItemWidth + 1)
    : 1;
};
