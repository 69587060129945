import Icon from "@atoms/icon/Icon";
import { Image } from "@atoms/image/Image";
import Typography from "@atoms/typography/Typography";
import { SpeakerProps } from "@sections/events-list/EventsListSection.types";
import { tw } from "@utils/tailwind";
import Link from "next/link";
import { FC } from "react";

export const Speaker: FC<SpeakerProps> = ({
  name,
  description,
  link,
  image,
}) => {
  const linkStyles = tw`group relative aspect-square
    h-auto w-[3.688rem] rounded-full`;

  const linkedinIconStyles = tw`absolute -bottom-1.5 -right-0.5 hidden size-6
    overflow-hidden rounded-full border-2 border-transparent 
    duration-100 ease-in-out
    group-hover:translate-y-[-0.313rem]
    group-hover:transition-transform
    group-focus:border-green-accent-two
    md:block`;

  return (
    <div className="grid grid-cols-[auto_1fr] gap-3.5">
      {image && (
        <a
          href={link?.href}
          target="_blank"
          rel="noopener noreferrer"
          className={linkStyles}
        >
          <Image
            backgroundImage
            maxKnownWidth={90}
            imageData={image}
            className="rounded-full object-cover"
          />
          {link?.href && (
            <div className={linkedinIconStyles}>
              <Icon
                name="LinkedInGray"
                className="size-full scale-125 fill-grey-mid"
              />
            </div>
          )}
        </a>
      )}

      <div className="text-balance text-content-primary-on-light">
        {name && (
          <Typography
            tag="p"
            variant="sm"
            weight="medium"
            className="tracking-[0.02em] md:tracking-normal"
          >
            {name}
          </Typography>
        )}
        {description && (
          <Typography tag="p" variant="xs" className="text-grey-mid">
            {description}
          </Typography>
        )}
        {link && (
          <Link
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
            className="mt-2 block text-xs md:hidden"
          >
            <Typography
              tag="span"
              variant="xs"
              weight="medium"
              className="tracking-[0.02em] underline"
            >
              {link.title}
            </Typography>
          </Link>
        )}
      </div>
    </div>
  );
};
