import { Slide } from "../TimelineSection.types";

const todayYear = new Date().getFullYear();
/**
 * It's a utility function that takes the current index of the slide in "slides" array and returns a bunch of useful
 * data like what's the next (previous) slide. It also returns the current slides group. This is because all the slides
 * are split into smaller groups that are related to the same year because we cannot always fit all the slides
 * on one screen.
 *
 * Each group of slides contains "startingInd" property which is the index of the first element in the group that
 * occurs in the "slides" array.
 * @param curSlideInd
 * @param slides
 */
export const getSlidesInfo = (curSlideInd: number, slides: Slide[]) => {
  const slidesAmount = slides.length;

  const isFirstSlide = curSlideInd === 0;
  const isLastSlide = curSlideInd === slidesAmount - 1;

  const curSlide = slidesAmount ? slides[curSlideInd] : undefined;
  const curYear = curSlide?.year ?? todayYear;

  const prevYear =
    slides.findLast((it) => it.year < curYear)?.year ?? curYear - 1;

  const nextYear = slides.find((it) => it.year > curYear)?.year ?? curYear + 1;

  const groupStartingInd = slides.findIndex((x) => x.year === curYear);
  const groupSize = slides.reduce(
    (acc, cur) => acc + (cur.year === curYear ? 1 : 0),
    0
  );

  return {
    years: {
      cur: curYear,
      prev: prevYear,
      next: nextYear,
    },
    slides: {
      isFirstSlide,
      isLastSlide,
      curGroup: {
        startingInd: groupStartingInd,
        size: groupSize,
      },
    },
  } as const;
};
