"use client";

import Icon from "@atoms/icon/Icon";
import { Image } from "@atoms/image/Image";
import Typography from "@atoms/typography/Typography";
import { RichTextFormatter } from "@content-sections/rich-text-formatter/RichTextFormatter";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { useIsVisible } from "@utils/use-is-visible/useIsVisible";
import { FC, useRef } from "react";
import { tw } from "utils/tailwind";
import { VlGroupSectionProps } from "./VlGroupSection.types";

export const VlGroupSection: FC<VlGroupSectionProps> = ({
  responsiveImages,
  rawHtmlDescription,
  title,
  themeName,
  anchorId,
}) => {
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const { hasIntersectedOnce } = useIsVisible(sectionRef, "160px");

  const logoStyles = tw`h-9 w-auto fill-icon-primary px-4 sm:h-[3.75rem] sm:px-5 md:h-[3.375rem] xl:h-[4.125rem] xl:w-full xl:grow xl:px-[3.75rem] 2xl:h-[5.75rem] 2xl:px-20`;

  return (
    <ContainerSpacing
      withBackground
      style={!hasIntersectedOnce ? { backgroundImage: "none" } : {}}
      id={anchorId}
      data-strapi="vl-group"
      data-theme={themeName}
      className="relative isolate after:absolute after:inset-0 after:-z-10 after:bg-[#181818CC] after:content-['']"
    >
      {responsiveImages && (
        <Image
          backgroundImage
          imageData={responsiveImages}
          className="-z-20 object-center"
        />
      )}
      <div className="container-flexible grid gap-14 sm:gap-20 md:max-w-[27.25rem] md:gap-24 xl:max-w-full xl:grid-cols-12 xl:items-center xl:gap-5 2xl:gap-10">
        <div
          ref={sectionRef}
          className="grid gap-3 sm:gap-7 md:gap-12 xl:col-span-5 xl:gap-7"
        >
          <Typography tag="h2" variant="h2" className="text-blue-light">
            {title}
          </Typography>
          <div className="t-body-b2 text-primary opacity-75">
            {RichTextFormatter({ content: rawHtmlDescription })}
          </div>
        </div>
        <div className="flex justify-center gap-5 xl:col-span-6 xl:justify-start 2xl:gap-10">
          <Icon name="VirtusLogoWithText" className={logoStyles} />
          <Icon name="SoftwaremillLogo" className={logoStyles} />
        </div>
      </div>
    </ContainerSpacing>
  );
};
