import { Image } from "@atoms/image/Image";
import { cn } from "@utils/tailwind";
import basicLayer from "public/images/burger/layer.png";
import { FC } from "react";
import { BurgerLayerProps } from "../BurgerSection.constants";
import { prepareStylesForLayers } from "../BurgerSection.helpers";

export const BurgerLayer: FC<BurgerLayerProps> = ({
  activeLayer,
  layerIndex,
  image,
}) => (
  <div
    className={cn(
      "transition-transform duration-500 md:absolute md:left-0 md:top-1/2",
      layerIndex !== 1 ? "absolute" : "relative",
      layerIndex === 0 && "z-30",
      layerIndex === 1 && "z-20 md:translate-y-[-91px] xl:translate-y-[-129px]",
      layerIndex === 2 && "md:top-auto",
      layerIndex === 2 && layerIndex !== activeLayer && "z-10",
      layerIndex === activeLayer && "z-40",
      prepareStylesForLayers(layerIndex, activeLayer)
    )}
  >
    <Image
      className={cn(
        "w-[250px] cursor-pointer transition duration-500 md:w-72 xl:w-96",
        activeLayer === layerIndex && "opacity-0"
      )}
      imageData={basicLayer.src}
    />
    <Image
      imageData={image}
      className={cn(
        "absolute bottom-0 w-[250px] cursor-pointer opacity-0 transition duration-500 md:w-72 xl:w-96",
        activeLayer === layerIndex && "opacity-100"
      )}
    />
  </div>
);
