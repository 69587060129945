import Typography from "@atoms/typography/Typography";
import { YourNeedsCard } from "@molecules/your-needs/YourNeedsCard";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import { MountainsBaseProps } from "./MountainsBase.types";

export const MountainsBase: FC<MountainsBaseProps> = ({
  title,
  cards,
  className,
}) => (
  <div
    className={cn(
      "relative bg-content-primary-on-dark lg:bg-transparent",
      className
    )}
  >
    <Typography
      tag="h3"
      variant="h3"
      className="container-flexible mb-5 pt-6 md:mb-10 lg:mb-12 lg:pt-12 xl:mb-14 xl:pt-8 2xl:mb-16 2xl:pt-9"
    >
      {title}
    </Typography>
    <div className="bg-content-primary-on-dark">
      <div className="container-flexible grid overflow-hidden rounded-4xl border border-green-accent-two lg:grid-cols-3">
        {cards?.map((card, idx) => (
          <YourNeedsCard
            {...card}
            linkBlockPresent
            key={idx}
            id={idx}
            color="light"
            linkPosition="bottom"
          />
        ))}
      </div>
    </div>
  </div>
);
