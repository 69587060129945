import { NavigationLineProps } from "@sections/timeline/components/types";
import { cn } from "@utils/tailwind";
import { CSSProperties, FC } from "react";

export const NavigationLine: FC<NavigationLineProps> = ({
  curSlideInd,
  slidesInfo,
}) => {
  const { slides } = slidesInfo;

  return (
    <div className="mb-5 grid grid-cols-[1fr_auto_1fr] items-center md:mb-9 xl:mb-10 2xl:mb-12">
      <div data-name="Line" className="h-0.5 rounded-full bg-blue-light" />

      <div
        data-name="Mid container"
        className="container-flexible grid w-[calc(var(--container-flexible-width)*1vw)] grid-cols-[auto_1fr_auto_1fr_auto] items-center"
      >
        <div
          data-name="Circle"
          className="size-3.5 rounded-full border-2 border-solid border-blue-light bg-transparent"
        />
        <div data-name="Line" className="h-0.5 rounded-full bg-blue-light" />

        <div
          data-name="Circle"
          className="size-3.5 rounded-full bg-blue-light"
        />

        <div
          data-name="Slides group"
          className="grid grid-cols-[repeat(var(--amount),1fr)] gap-1"
          style={{ "--amount": slides.curGroup.size } as CSSProperties}
        >
          {new Array(slides.curGroup.size).fill(0).map((_, offset) => (
            <div
              key={offset}
              data-name="Line"
              className={cn(
                "h-0.5 rounded-full",
                curSlideInd >= slides.curGroup.startingInd + offset
                  ? "bg-green-accent-one"
                  : "bg-green-saturated"
              )}
            />
          ))}
        </div>

        <div
          data-name="Circle"
          className="size-3.5 rounded-full border-2 border-solid border-green-saturated bg-transparent"
        />
      </div>

      <div data-name="Line" className="h-0.5 rounded-full bg-green-saturated" />
    </div>
  );
};
