import { Image } from "@atoms/image/Image";
import { cva } from "class-variance-authority";
import Link from "next/link";
import { ComponentPropsWithoutRef, FC } from "react";
import { HeaderHomeLogo } from "../HeaderHomepageSection.types";

const imageStyles = cva("w-auto", {
  variants: {
    variant: {
      tisax: "h-7 md:h-10 2xl:h-12",
      eu: "h-7 md:h-10 2xl:h-12",
      tuv: "h-14 w-auto md:h-20 2xl:h-24",
    },
  },
});

/**
 * Utility component to wrap children with Link if href is not empty
 */
const CustomLink: FC<ComponentPropsWithoutRef<typeof Link>> = (props) =>
  props.href ? <Link {...props} /> : props.children;

export type LogosProps = {
  logos: HeaderHomeLogo[];
};

export const Logos: FC<LogosProps> = ({ logos }) => {
  if (!logos.length) return null;
  return (
    <div className="container-flexible absolute left-1/2 top-24 z-10 -translate-x-1/2 text-right md:top-32">
      <div className="inline-flex flex-wrap gap-9 md:gap-12">
        {logos.map((it) => (
          <CustomLink key={it.variant} href={it.href || ""}>
            <Image
              imageData={it.image}
              className={imageStyles({ variant: it.variant })}
            />
          </CustomLink>
        ))}
      </div>
    </div>
  );
};
