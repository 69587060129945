import { FC } from "react";
import { OverlayProps } from "../SuccessStoriesSection.types";
import { StatementsCard } from "../cards/StatementsCard";

export const DesktopOverlay: FC<Omit<OverlayProps, "className">> = ({
  ...props
}) => (
  <StatementsCard
    {...props}
    className={`absolute
    -left-full
    top-0
    hidden
    overflow-hidden
    max-xl:group-hover/card:left-0
    md:flex
    xl:-top-full
    xl:left-0
    xl:group-hover/card:top-0`}
  />
);
