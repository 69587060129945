import { FC } from "react";
import { StatementsCardProps } from "../SuccessStoriesSection.types";
import { StatementsCard } from "../cards/StatementsCard";

/** Invisible statements to set correct height for the parent element */
export const MobileInvisibleStatements: FC<
  Omit<StatementsCardProps, "className">
> = (props) => (
  <StatementsCard
    {...props}
    className="invisible h-fit [grid-area:mobile-overlay] md:hidden"
  />
);
