"use client";

import { cn } from "@utils/tailwind";
import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { calcAnimatedItemsAmount } from "./Looper.helpers";

export type LoopDirection = "left" | "right";

export type LooperProps = {
  speed?: number;
  className?: string;
  direction?: LoopDirection;
  children: ReactNode;
};

export const Looper: FC<LooperProps> = ({
  speed = 3,
  direction = "right",
  children,
  className,
}) => {
  const rootWrapperRef = useRef<HTMLDivElement>(null);
  const firstAnimatedItemRef = useRef<HTMLDivElement>(null);
  const [itemsAmount, setItemsAmount] = useState(1);
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    const resetAnimation = () => {
      setAnimate(false);
      setTimeout(() => {
        if (firstAnimatedItemRef.current) setAnimate(true);
      }, 10);
    };

    const update = () => {
      setItemsAmount((prevAmount) => {
        const nextAmount = calcAnimatedItemsAmount(
          firstAnimatedItemRef.current?.getBoundingClientRect()?.width,
          rootWrapperRef.current?.getBoundingClientRect()?.width
        );
        if (prevAmount < nextAmount) resetAnimation();
        return nextAmount;
      });
    };

    update(); // Initial update

    window.addEventListener("resize", update);
    return () => {
      window.removeEventListener("resize", update);
    };
  }, []);

  return (
    <div
      className={cn("w-full overflow-hidden", className)}
      ref={rootWrapperRef}
    >
      <div className="flex w-fit justify-center">
        <div
          key="first-one"
          ref={firstAnimatedItemRef}
          className={cn(
            animate ? "animate-[slide_linear_infinite]" : "",
            "flex w-max"
          )}
          style={{
            animationDuration: `${speed}s`,
            animationDirection: `${direction === "right" ? "reverse" : "normal"}`,
          }}
        >
          {children}
        </div>

        {Array(itemsAmount - 1)
          .fill(0)
          .map((_, ind) => (
            <div
              key={ind}
              className={cn(
                animate ? "animate-[slide_linear_infinite]" : "",
                "flex w-max"
              )}
              style={{
                animationDuration: `${speed}s`,
                animationDirection: `${direction === "right" ? "reverse" : "normal"}`,
              }}
            >
              {children}
            </div>
          ))}
      </div>
    </div>
  );
};
