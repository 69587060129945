import { tw } from "@utils/tailwind";
import { CarouselImageVariant } from "./Carousel.types";

export const generateImageStyle = (imageVariant: CarouselImageVariant) => {
  switch (imageVariant) {
    case "horizontal": {
      return tw`aspect-[4/3]`;
    }
    case "horizontal-small": {
      return tw`aspect-[4/3]`;
    }
    case "vertical": {
      return tw`aspect-[9/16]`;
    }
    case "square": {
      return tw`aspect-square`;
    }
  }
};
