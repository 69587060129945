import { useState, useEffect, RefObject } from "react";

type IntersectionResult = {
  isVisible: boolean;
  hasIntersectedOnce: boolean;
};

export const useIsVisible = (
  ref: RefObject<HTMLElement | null>,
  rootMargin = "0px",
  threshold?: number
): IntersectionResult => {
  const [intersectionResult, setIntersectionResult] =
    useState<IntersectionResult>({
      isVisible: false,
      hasIntersectedOnce: false,
    });

  useEffect(() => {
    const refObject = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        const isVisible = entry.isIntersecting;

        setIntersectionResult((prevResult) => ({
          isVisible,
          hasIntersectedOnce: prevResult.hasIntersectedOnce || isVisible,
        }));
      },
      {
        rootMargin,
        threshold,
      }
    );

    if (refObject) {
      observer.observe(refObject);
    }

    return () => {
      if (refObject) {
        observer.unobserve(refObject);
      }
    };
  }, [ref, rootMargin, threshold]);

  return intersectionResult;
};
