import { useEffect, useLayoutEffect, useState } from "react";

interface Resolution {
  width?: number;
  height?: number;
}

export const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

export const useResolution = (): Resolution => {
  const [resolution, setResolution] = useState<Resolution>({
    width: undefined,
    height: undefined,
  });

  const handleResize = () => {
    setResolution({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useIsomorphicLayoutEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return resolution;
};
