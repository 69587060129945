import { Image } from "@atoms/image/Image";
import { LogosProps } from "@molecules/logo-looper/LogoLooper.types";
import { ApiImage } from "@page-components/types";
import { cn, tw } from "@utils/tailwind";
import { isObject } from "lodash";
import { FC, Fragment } from "react";

const itemStyles = tw`group/logo-item
  relative
  mx-2
  flex
  h-10
  w-[5.625rem]
  items-center
  justify-center
  md:h-[4.375rem]
  md:w-[8.125rem]
  xl:mx-4`;

export const Logos: FC<LogosProps> = ({ logos, isMobile }) =>
  logos.map((logo, idx) => {
    const Wrapper = isMobile ? Fragment : "li";
    const wrapperProps = isMobile ? {} : { className: "flex max-h-full" };

    const anchorProps = {
      href: logo.href,
      target: "_blank",
      rel: "noreferrer",
      className: itemStyles,
    };

    const spanProps = { className: itemStyles };
    const Item = logo.href ? "a" : "span";
    const itemProps = logo.href ? anchorProps : spanProps;

    const imageStyles = cn(
      "object-contain",
      logo.href &&
        `opacity-80 transition-opacity duration-200 ease-in-out
        group-hover/logo-item:opacity-100`
    );

    return (
      <Wrapper key={`logo-${idx}`} {...wrapperProps}>
        <Item {...itemProps}>
          {isObject(logo.imageOrJSX) ? (
            <Image
              backgroundImage
              className={imageStyles}
              imageData={logo.imageOrJSX as ApiImage}
              maxKnownWidth={200}
              // Note: eager is important here for Safari to load images out of viewport on mobile
              loading="eager"
            />
          ) : (
            logo.imageOrJSX
          )}
        </Item>
      </Wrapper>
    );
  });
